.edit-image {
    margin: 10px;
    width: 200px;
    height: 200px;
}
.edit-image :hover {
    cursor: pointer;
}
.card-title {
    display: flex;
    text-align: left;
}
.card-text {
    text-align: left;
}

.text-right {
    text-align: right;
}
.card-horizontal {
    display: flex;
    flex: 1 1 auto;
}
.card-body {
    width: 490px;
}

.btns {
    margin-top:10%;
    margin-left: auto;
    margin-right: auto;
}

.post-text {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -50px;
}
.FormContainer {
    text-align: left;
    display: block;
}
.form {
    width: 100%;
    height: auto;
    background: rgba(0, 0, 0, 0.1);
    padding: 30px;
    resize: horizontal;
}


@media only screen and (max-width: 768px) {
    .FormContainer {
        width: 90%;
        margin-top: 100px;
        text-align: left;
        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (max-width: 550px) {
    .FormContainer {
        width: 100%;
        margin-top: 100px;
        text-align: left;
        margin-left: auto;
        margin-right: auto;
    }
    #save{
        position:absolute;
        display: flex;
        margin-left: auto;
    }
    #cancel{
        position:relative;
        margin-left: 65%;
    }

}

@media only screen and (max-width: 497px) {
    .FormContainer {
        width: 100%;
        margin-top: 100px;
        text-align: left;
        margin-left: auto;
        margin-right: auto;
    }
    #save{
        position:absolute;
        display: flex;
        margin-left: auto;
    }
    #cancel{
        position:relative;
        margin-left: 65%;
    }
    .delete {
        margin-top: 50px;
        margin-bottom: 50px;
        width: 100%;
        height: 350px;
        background: rgba(255, 0, 0, 0.1);
        padding-left: 30px;
        padding-top: 30px;
        padding-right: 20px;
        border: 1px solid red;
        resize: horizontal;
    }

    .delete-btn {
        position:absolute;
        margin-top: 10%;
        margin-left:50%;
    }
}