.sidebar-admin {
  max-height: 1500rem;
  min-height: 900px;
  height: 102%;
  width:100%;
  left: 0;
  background-color: #111;
  top: 63px;
}

.sidebar-admin a {
  padding: 20px 30px 15px 15px;
  text-decoration: none;
  font-size: 15px;
  color: #818181;
  display: block;
}

.sidebar-admin a:hover {
  color: #f1f1f1;
  text-decoration: none;
  background-color: rgb(95, 95, 95);
}
